<script>
  export default {
    name: 'Heading',

    props: {
      comp: Object
    },

    render(h) {
      let comp = this.comp
      return h(comp.settings.tag, {
        attrs: {
          id: comp.settings.id ? comp.settings.id : undefined,
          class: comp.settings.class ? comp.settings.class : undefined
        },
        domProps: {
          innerHTML: comp.settings.text
        }
      })
    }
  }
</script>
